import { CaseType, ReasonItemType, ReasonTypes, RuleType } from './caseSlice'


export enum ruleUpdateResponseAssignmentResponse {
  SEND_TO_HIL_POOL ='send_to_hil_pool',
  SEND_TO_RAD_POOL="send_to_rad_pool",
  SEND_TO_MODEL="send_to_model",
  NOT_CHANGED= "not_changed",
}

export type onReceiveGetCasesInPoolCountPayloadType = {
  pool_count: number
}

export type RequestGetCasesInPoolCountPayloadType = {
  email: string | undefined
}

export type RequestGetNewCasePayloadType = {
  email: string | undefined
}

export type onReceiveGetNewCasePayloadType = {
  details: CaseType
}

export type RequestCaseByDatePayloadType = {
  formattedDate: any
  email: string | undefined
}

export type onReceiveSkipReasonsPayloadType = ReasonItemType[]

export type RequestSkipReasonPayloadType = {
  reasonType: ReasonTypes
}

export type OnReceiveModStudyType = RuleType[]

export type OnRequestUpateModStudy = {
  study_id: number
  rules: RuleType[]
  by_user_id?: number | string
}

export type OnRequestEligibleOnlineUsers = {
  mod_study: number | string
  type_id: number
}
