import React, { Fragment, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Checkbox, Col, Form, FormInstance, GlobalToken, Radio, Row, Select, theme } from 'antd'
import styled from 'styled-components'
import { Typography } from 'antd'
import { MandatoryItemType, RuleType, requestModStudies } from '../redux/cases/caseSlice'
import { filterModstudies, getDefaultGender } from '../utils/helper'
import { useAppDispatch, useAppSelector } from '../redux/utils/hooks'
import { Gender } from '../utils/constants'

const { Text, Paragraph } = Typography

export type StudySelectType = {
  patientData?: any
  formName: string[]
  isEditable?: boolean
  mergedStudyIndex?: number
  unMergeStudyFunction?: (index: number) => void
  form?: FormInstance<any>
  onChangeFunction?: () => any
  ruleIdsToExclude?: number[]
  defaultRule?: RuleType
}
const { useToken } = theme

const StudySelect = (props: StudySelectType, ref: any) => {
  const {
    patientData,
    formName,
    isEditable = true,
    mergedStudyIndex,
    form,
    onChangeFunction,
    ruleIdsToExclude,
    defaultRule,
  } = props
  const dispatch = useAppDispatch()
  const { token } = useToken()

  //States
  const [selectedStudies, setSelectedStudies] = useState<number[]>([])
  const modStudiesOptions = useRef<{ value: number; label: string }[]>([])
  const [warningList, setWarningList] = useState<string[][]>([])
  const [filteredModStudies, setFilteredModStudies] = useState<RuleType[]>([])

  //Redux states
  const { modStudiesList }: { modStudiesList: RuleType[] } = useAppSelector((state: any) => state.cases)

  //Fetch the study list
  useEffect(() => {
    if (!Boolean(modStudiesList?.length)) {
      dispatch(requestModStudies())
    }
  }, [])

  useEffect(() => {
    const filteredModStudyList: RuleType[] = filterModstudies(modStudiesList ?? [], ruleIdsToExclude)
    setFilteredModStudies([...filteredModStudyList])
  }, [modStudiesList, ruleIdsToExclude])

  //Trigger onChangeFunction on selection of new study
  useEffect(() => {
    onChangeFunction?.()
  }, [selectedStudies])

  //Destructuring studies data because redux give only readable state and removing the non default selected items
  const studiesModifier: RuleType[] = useMemo(() => {
    let newItem: RuleType

    const abc = filteredModStudies.map((item: RuleType) => {
      newItem = { ...item }

      //Destructuring to clone children
      if (item.children) {
        if (item.children?.length > 0) {
          newItem.children = [...item.children]
          item.children?.forEach(_ => {
            if (newItem.children?.length > 1) {
              newItem.children?.splice(1, item.children.length - 1)
            }
          })
        }
      }

      // Destructuring to clone mandatory
      if (newItem.mandatory && item.mandatory && item.mandatory?.length > 0) {
        newItem.mandatory = [...item.mandatory]
        newItem.mandatory?.forEach((_, index) => {
          newItem.mandatory[index] = { ...item.mandatory[index] }
          newItem.mandatory[index].values = [...(item.mandatory[index]?.values || {})]
          if (newItem.mandatory[index]?.values.length > 0) {
            newItem.mandatory[index]?.values?.splice(0, item.mandatory[index].values.length)
          }
          if (newItem.mandatory[index]?.type === MandatoryItemType.GENDER) {
            newItem.mandatory[index]?.values?.push(getDefaultGender(patientData?.patient_sex))
          }
        })
      }

      //Destructuring to clone additional
      if (item?.additional && item?.additional?.length > 0) {
        newItem.additional = [...item.additional]
        newItem.additional?.forEach((_, index) => {
          newItem.additional[index] = { ...item.additional[index] }
          newItem.additional[index].list = [...(item.additional[index]?.list || {})]
          if (newItem.additional[index]?.list.length > 0) {
            newItem.additional[index]?.list?.splice(0, item.additional[index].list?.length)
          }
        })
      }
      return newItem
    })
    return abc
  }, [filteredModStudies])

  modStudiesOptions.current = useMemo(() => {
    return filteredModStudies?.map((ele, index) => {
      return { value: index, label: ele.list?.join(' ') }
    })
  }, [filteredModStudies])

  //Functions to select and deselect plain and contrast
  const childrenToggler = (
    checked: boolean,
    index: number,
    value: string,
    item: { list: string[]; mod_study: number }
  ) => {
    let exists = false
    if (checked && studiesModifier[index]?.children?.length >= 0) {
      studiesModifier[index]?.children?.map((_, mappedIndex) => {
        if (studiesModifier[index]?.children[mappedIndex]?.list.includes(value)) {
          exists = true
        }
      })
      if (!exists || studiesModifier[index]?.children?.length === 0) {
        studiesModifier[index]?.children.push(item)
      }
    }
    if (!checked && studiesModifier[index]?.children?.length > 0) {
      studiesModifier[index]?.children.map((_, mappedIndex) => {
        if (studiesModifier[index]?.children[mappedIndex]?.list.includes(value)) {
          studiesModifier[index]?.children.splice(mappedIndex, 1)
        }
      })
    }
    onChangeFunction?.()
  }

  //Functions to select and deselect mandatory other than type Gender
  const toggleSideAndView = (checked: boolean, index: number, value: string, objIndex: number) => {
    if (checked) {
      if (!studiesModifier[index]?.mandatory[objIndex]?.values.includes(value)) {
        studiesModifier[index]?.mandatory[objIndex].values.push(value)
      }
    }
    if (!checked) {
      studiesModifier[index]?.mandatory[objIndex]?.values?.forEach((existingValue, indexTracker) => {
        if (existingValue === value) {
          studiesModifier[index]?.mandatory[objIndex]?.values?.splice(indexTracker, 1)
        }
      })
    }
    onChangeFunction?.()
  }

  //Functions to select and deselect mandatory of type Gender
  const genderToggler = (index: number, value: string, objIndex: number) => {
    studiesModifier[index].mandatory[objIndex].values[0] = value
    onChangeFunction?.()
  }

  //Functions to select and deselect additionals
  const additioanlToggler = (checked: boolean, index: number, value: string, objIndex: number) => {
    if (checked) {
      if (!(studiesModifier[index]?.additional[objIndex]?.list?.length > 0)) {
        filteredModStudies[index]?.additional[objIndex]?.list?.map(study => {
          studiesModifier[index]?.additional[objIndex].list.push(study)
        })
      }
    }
    if (!checked) {
      if (studiesModifier[index]?.additional[objIndex]?.list?.length > 0) {
        studiesModifier[index]?.additional[objIndex]?.list?.splice(
          0,
          studiesModifier[index]?.additional[objIndex]?.list?.length
        )
      }
    }
    onChangeFunction?.()
  }

  // info : fuzzy search function
  const StudyFilterOption = (searchString: string, valueObject: any) => {
    const searchText = searchString.toLowerCase()?.split(' ')
    const exactValue = valueObject?.label?.toLowerCase()
    return !searchText?.find(elem => !exactValue?.includes(elem))
  }

  //This will asssign the function to current of passed useRef to call functions in parent component
  useImperativeHandle(ref, () => {
    return {
      //A custom validation function
      RulesValidator: () => {
        if (!Boolean(selectedStudies?.length)) {
          return false
        }
        let shouldShowWarning = false
        const duplicateWarningArray: string[][] = []
        selectedStudies.forEach((ele: number) => {
          const duplicateWarningList: string[] = []
          if (studiesModifier[ele]?.children?.length === 0) {
            duplicateWarningList.push('Please select Type')
          }
          studiesModifier[ele]?.mandatory?.map((item: any) => {
            if (item?.values?.length == 0 || item?.values?.[0]?.length == 0) {
              duplicateWarningList.push('Please select ' + item.type)
              shouldShowWarning = true
            }
          })
          duplicateWarningArray.push([...duplicateWarningList])
        })

        setWarningList(duplicateWarningArray)

        return !shouldShowWarning
      },

      //Function that provides the final rule
      getRules: () => {
        return selectedStudies?.map((ele: number) => {
          const finalAdditional = studiesModifier[ele]?.additional?.filter(additional => {
            return additional.list.length
          })

          const finalChildren = studiesModifier[ele]?.children?.filter(children => {
            return children.list.length
          })

          const finalMandatory = studiesModifier[ele]?.mandatory?.filter(mandatory => {
            return mandatory.values.length
          })

          const rule = { ...studiesModifier[ele] }
          //Keys needs to be removed before they are added dynamically
          delete (rule as any)?.additional
          delete (rule as any)?.children
          delete (rule as any)?.mandatory

          return {
            ...rule,
            ...(finalAdditional?.length && { additional: finalAdditional }),
            ...(finalChildren?.length && { children: finalChildren }),
            ...(finalMandatory?.length && { mandatory: finalMandatory }),
          }
        })
      },
    }
  })

  const childrenDefaultSelect = (ele: number, item: { list: string[]; mod_study: number }) => {
    let exists = false
    studiesModifier?.[ele]?.children?.map((_, mappedIndex) => {
      if (studiesModifier[ele]?.children[mappedIndex]?.list.includes(item.list[0])) {
        exists = true
      }
    })
    return exists
  }

  //This useEffect will set the selectedStudies state, if the draft was unmerged once !
  //Since form is created dynamically for merge studies, form already contains the previously selected studies
  useEffect(() => {
    if (form !== undefined && mergedStudyIndex !== undefined) {
      const form_value = form.getFieldsValue()
      const preSelectedStudies = form_value?.user?.['Merged_Studies' + patientData.study_iuid] || []
      setSelectedStudies([...preSelectedStudies])
    }
    onChangeFunction?.()
  }, [form])


  //Function to get Select tag based on whether the select study is for correlation or others
  const getSelectTag = () => {
    return (
      <Select
        disabled={!isEditable}
        allowClear
        showSearch
        placeholder={'Select studies'}
        optionFilterProp='children'
        filterOption={StudyFilterOption}
        value={selectedStudies}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={modStudiesOptions.current}
        aria-multiselectable={false}
        onChange={value => {
          if (value === undefined) {
            setSelectedStudies([])
          } else {
            //@ts-ignore
            setSelectedStudies([value])
          }
          //set warning list to [] to solve an edge case where rule validation is failed and removed one study (not last selected study)
          setWarningList([])
        }}
      />
    )
  }

  return (
    <Container token={token}>
      <Row
        gutter={[2, 0]}
        align='top'
        justify='space-between'
      >
        <Col span={patientData ? 14 : 24}>
          <Form.Item
            name={formName}
            label={<b>{'Select study'}</b>}
          >
            {getSelectTag()}
          </Form.Item>
          {Boolean(selectedStudies?.length) &&
            studiesModifier &&
            filteredModStudies &&
            selectedStudies?.map((ele: number, studyIndex: number) => {
              return (
                <Fragment key={ele}>
                  <StudiesSelectionContainer
                    key={ele}
                    align='top'
                    justify='space-between'
                    token={token}
                  >
                    <Col>
                      {' '}
                      <Text strong>{studiesModifier[ele]?.list.join(' ')}</Text>
                    </Col>
                    <Col>
                      <Row
                        gutter={[30, 0]}
                        align={'top'}
                      >
                        {filteredModStudies[ele]?.children && (
                          <Col>
                            <Paragraph
                              strong
                              type='success'
                            >
                              Select Type
                            </Paragraph>
                            {filteredModStudies[ele]?.children &&
                              filteredModStudies[ele]?.children?.map((item, objIndex) => {
                                return (
                                  <Checkbox
                                    disabled={!isEditable}
                                    key={item.list[0]}
                                    defaultChecked={childrenDefaultSelect(ele, item)}
                                    onChange={e => {
                                      childrenToggler(e.target.checked, ele, item.list[0], item)
                                    }}
                                    style={{ margin: '0px' }}
                                  >
                                    {item.list[0]}
                                  </Checkbox>
                                )
                              })}
                          </Col>
                        )}
                        {filteredModStudies[ele]?.mandatory?.map((item, objIndex) => {
                          return (
                            <div key={`mandatory_${objIndex}`}>
                              {item?.type === MandatoryItemType.SIDE && (
                                <Col>
                                  <Paragraph
                                    strong
                                    type='success'
                                  >
                                    Select {item?.type}
                                  </Paragraph>
                                  {item.values.map(valueEle => {
                                    return (
                                      <Checkbox
                                        disabled={!isEditable}
                                        key={valueEle}
                                        defaultChecked={studiesModifier?.[ele]?.mandatory?.[objIndex]?.values?.includes(
                                          valueEle
                                        )}
                                        onChange={e => {
                                          toggleSideAndView(e.target.checked, ele, valueEle, objIndex)
                                        }}
                                      >
                                        {valueEle}
                                      </Checkbox>
                                    )
                                  })}
                                </Col>
                              )}
                              {item?.type === MandatoryItemType.GENDER && (
                                <Col>
                                  <Paragraph
                                    strong
                                    type='success'
                                  >
                                    Select {item?.type}
                                  </Paragraph>
                                  <Radio.Group
                                    disabled={!isEditable}
                                    onChange={radioEvent => {
                                      genderToggler(ele, radioEvent.target.value, objIndex)
                                    }}
                                    defaultValue={studiesModifier?.[ele]?.mandatory?.[objIndex]?.values?.[0]}
                                  >
                                    <Radio value={Gender.MALE}>Male</Radio>
                                    <Radio value={Gender.FEMALE}>Female</Radio>
                                  </Radio.Group>
                                </Col>
                              )}
                              {item?.type === MandatoryItemType.VIEW && (
                                <Col>
                                  <Paragraph
                                    strong
                                    type='success'
                                  >
                                    Select {item?.type}
                                  </Paragraph>
                                  {item.values.map(valueEle => {
                                    return (
                                      <Checkbox
                                        disabled={!isEditable}
                                        key={valueEle}
                                        defaultChecked={studiesModifier?.[ele]?.mandatory?.[objIndex]?.values?.includes(
                                          valueEle
                                        )}
                                        onChange={e => {
                                          toggleSideAndView(e.target.checked, ele, valueEle, objIndex)
                                        }}
                                      >
                                        {valueEle}
                                      </Checkbox>
                                    )
                                  })}
                                </Col>
                              )}
                            </div>
                          )
                        })}
                        <Col>
                          {filteredModStudies[ele]?.additional?.length > 0 && (
                            <Paragraph
                              strong
                              type='success'
                            >
                              Additional studies
                            </Paragraph>
                          )}
                          {filteredModStudies[ele]?.additional?.map((item, objIndex) => {
                            const additionalStudy = item.list.join(' ')
                            return (
                              <Checkbox
                                disabled={!isEditable}
                                key={additionalStudy}
                                defaultChecked={studiesModifier?.[ele]?.additional?.[objIndex]?.list?.length > 0}
                                onChange={e => {
                                  additioanlToggler(e.target.checked, ele, additionalStudy, objIndex)
                                }}
                              >
                                {additionalStudy}
                              </Checkbox>
                            )
                          })}
                        </Col>
                      </Row>
                    </Col>
                  </StudiesSelectionContainer>
                  {warningList.length > studyIndex && warningList[studyIndex].length > 0 && (
                    <Text type='danger'>
                      {warningList.length > studyIndex &&
                        warningList[studyIndex].map((missedValue: string, index: number) => {
                          return (
                            <span
                              style={{ fontSize: '12px' }}
                              key={missedValue}
                            >
                              {index != 0 ? ', ' : ' '}
                              {missedValue}
                            </span>
                          )
                        })}
                    </Text>
                  )}
                </Fragment>
              )
            })}
        </Col>
      </Row>
    </Container>
  )
}

//wraping it with forward ref to use useImerativeHandle hook
export default React.forwardRef(StudySelect)

interface GlobalStyledProps {
  token?: GlobalToken
}

const Container = styled.div<GlobalStyledProps>`
  padding: 1rem 0.5rem;
  margin: 0.1rem 2%;
  margin-top: 1rem;
  border-radius: 0.5rem;
  background-color: #d9d9d9;
`
const StudiesSelectionContainer = styled(Row)<GlobalStyledProps>`
  border: solid 0.01rem ${props => props.token && props.token.colorBorder};
  padding: 0.5rem;
  margin-bottom: 1rem;
  padding-right: 2rem;
  border-radius: 0.5rem;
  position: relative;
`
