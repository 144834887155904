import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { RadioButton } from 'primereact/radiobutton'
import { FullScreen, FullScreenHandle, useFullScreenHandle } from 'react-full-screen'
import 'primeicons/primeicons.css'
import { auth } from '../Auth/firebaseAuth'
import {
  AI_SERVICE_URL,
  CHEST_MOD_STUDY,
  NON_SKIPPABLE_CLIENT_IDS,
  TB_SCREENING_MOD_STUDY,
  TextColors,
  VariablesSelectionType,
  XRAY_BIONIC,
  XRAY_STUDY,
} from '../utils/constants'
import { useAppDispatch, useAppSelector } from '../redux/utils/hooks'
import { ActionTypes, requestGetReportTemplate, requestGetStudy, requestTakeAction } from '../redux/reports/reportSlice'
import { Form, Formik, Field } from 'formik'
import FindingsCard from '../components/FindingsCard'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { notification, Typography, Checkbox, Flex, Tag, Input, Spin, message, Modal } from 'antd'
import OverlayLoader from '../components/shared/OverlayLoader'
import OverlayModal from '../components/shared/OverlayModal'
import { theme } from '../components/shared/theme'
import { PlateEditor } from '../components/plate-editor'
import { Button as AntButton } from 'antd'
import styled from 'styled-components'
import {
  doesRuleNamesMatch,
  getRuleName,
  isInFlightFailed,
  isInFlightPending,
  isInFlightSuccess,
} from '../utils/helper'
import { InFlightNames } from '../utils/types'
import ReportSkipModal from '../components/Reporting/ReportSkipModal'
import Icon from '../components/shared/Icon'
import StudySelect from '../components/StudySelect'
import { RuleType, requestUpdateModStudy } from '../redux/cases/caseSlice'
import XrayStudy from './XrayStudy'
import { Toast } from 'primereact/toast'

type NotificationType = 'success' | 'info' | 'warning' | 'error'
const { Text } = Typography
export interface FormData {
  pathologies: any
  variables: any
}

type reportData = {
  studyData: any
  viewer: any
}

function Reporting(data: reportData) {
  const { studyData, viewer } = data

  const { is_ai_report, mod_study, is_demo } = studyData ?? {}
  const { FormData } = useAppSelector(state => state.report.reports)
  const { hilEligibleStudies, inFlights: caseInflights } = useAppSelector(state => state.cases)
  const { email } = useAppSelector(state => state.auth.user)

  const navigate = useNavigate()

  const [modStudyId, setModStudyId] = useState<number>(mod_study)
  const [editorValue, setEditorValue] = useState<Array<Node>>([])
  const [searchInput, setSearchInput] = useState('')
  const screen1 = useFullScreenHandle()
  const screen2 = useFullScreenHandle()
  const [isEditable, setIsEditable] = useState(true)

  const [userEmail, setUserEmail] = useState('')
  const [ctRatioValue, setCtRatioValue] = useState<string>(studyData?.ct_ratio || '')
  const [view, setView] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditorLoading, setIsEditorLoading] = useState(false)
  const dispatch = useAppDispatch()

  const [selectedFindings, setSelectedFindings] = useState<any>([])
  const [pathologies, setPathologies] = useState<any>([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [isSkipModalOpen, setIsSkipModalOpen] = useState(false)
  const [ruleUpdateConfirmationModalOpen, setRuleUpdateConfirmationModalOpen] = useState<boolean>(false)

  const { inFlights } = useAppSelector(state => state.report)
  const { modStudiesList, modStudyEligibleUsers } = useAppSelector(state => state.cases)
  const { id } = useAppSelector(state => state?.auth?.user)
  const [defaultRule, setDefaultRule] = useState<RuleType | undefined>()
  const [disableSaveRule, setDisableSaveRule] = useState(true)

  const toast = useRef(null)

  const [api, contextHolder] = notification.useNotification()

  const studySelectFunctionRef = useRef<any>()

  const openNotificationWithIcon = (type: NotificationType, message = '', description = '') => {
    api[type]({
      message,
      description,
    })
  }

  const handleSearchInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchInput(event.target.value)
  }

  useEffect(() => {
    const filteredPathology =
      FormData?.pathologies
        ?.filter((item: { pathology: string }) =>
          Boolean(item.pathology.toLowerCase().includes(searchInput.toLowerCase()))
        )
        ?.sort((a: { pathology: string }, b: { pathology: string }) => {
          const aIsNormal = a.pathology.toLowerCase() === 'normal'
          const bIsNormal = b.pathology.toLowerCase() === 'normal'

          if (aIsNormal && !bIsNormal) {
            return -1 // "Normal" comes before other values
          } else if (!aIsNormal && bIsNormal) {
            return 1 // Other values come after "Normal"
          } else {
            return 0 // Maintain the existing order
          }
        }) ?? []
    setPathologies([...filteredPathology])
  }, [FormData])

  useEffect(() => {
    const user = auth.currentUser
    if (user) {
      setUserEmail(user.email!)
    }
    if (mod_study === CHEST_MOD_STUDY) {
      fetch(`${AI_SERVICE_URL}/report/model-findings?study_iuid=${studyData?.study_iuid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response1 => {
          return response1.json()
        })
        .then(data1 => {
          if (data1?.error) {
            navigate('/error')
          } else {
            setSelectedFindings(data1?.findings ?? [])
          }
        })
        .catch(error => {
          navigate('/error')
          console.error('Error:', error)
        })
    }
  }, [])

  useEffect(() => {
    if (modStudyId) {
      try {
        const type_fk = studyData?.type_id ?? 1
        dispatch(requestGetReportTemplate({ modStudyId, type_fk }))
      } catch (err) {
        console.log(err)
        navigate('/error')
      }
    }
  }, [modStudyId])

  const handleInputChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCtRatioValue(event.target.value)
  }

  const handleViewChange = (checkedValue: string[]) => {
    setView(checkedValue)
  }

  const handleCategoryChange = (category: React.SetStateAction<string>) => {
    setSelectedCategory(category)
  }

  const handleAddButtonClick = (values: any) => {
    if (values && selectedCategory != '') {
      const findingObject = {
        pathology: selectedCategory,
        info: [values],
      }

      setSelectedFindings([...selectedFindings, findingObject])
      setSelectedCategory('')
      setSearchInput('')
    }
  }

  const handleClearButtonClick = () => {
    setSelectedCategory('')
    setSelectedFindings([])
    setSearchInput('')
  }

  const handleEditButtonClick = () => {
    const formattedFindings = selectedFindings

    if (!formattedFindings?.length && mod_study !== TB_SCREENING_MOD_STUDY) {
      openNotificationWithIcon('error', 'Missing', 'Please select the pathology')
      return
    }

    setIsEditorLoading(true)

    let updatedFindings = formattedFindings

    if (ctRatioValue) {
      const data = { pathology: 'CTR', info: [{ measure: ctRatioValue }] }
      updatedFindings = [...updatedFindings, data]
    }

    const apiData = {
      study_id: studyData?.study_id,
      findings: updatedFindings,
    }

    fetch(`${AI_SERVICE_URL}/report/slate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
    })
      .then(response => response.json())
      .then(data => {
        setIsEditorLoading(false)

        if (data.report) {
          setEditorValue(data.report)
          setIsEditorOpen(true)
        } else {
          openNotificationWithIcon('error', 'Error', 'Not able to edit the report')
        }
      })
      .catch(error => {
        setIsEditorLoading(false)
        console.error('Error:', error)
        openNotificationWithIcon('error', 'Error', 'Not able to edit the report')
      })
  }

  const handleReportButtonClick = () => {
    const formattedFindings = selectedFindings
    let other_findings = null

    if (ctRatioValue) {
      const data = { pathology: 'CTR', info: [{ measure: ctRatioValue }] }
      formattedFindings.push(data)
    }

    if (mod_study === TB_SCREENING_MOD_STUDY) {
      if (!view?.length) {
        openNotificationWithIcon('error', 'Missing', 'Please select the view')
        return
      } else {
        other_findings = { view }
      }
    }

    if (!formattedFindings?.length && mod_study !== TB_SCREENING_MOD_STUDY) {
      openNotificationWithIcon('error', 'Missing', 'Please select the pathology')
      return
    }

    setIsLoading(true)

    let apiData = {}
    if (isEditorOpen && editorValue?.length) {
      apiData = {
        result: {
          action: ActionTypes.EDIT,
          study_id: studyData?.study_id,
          findings: formattedFindings,
          other_findings,
          json: editorValue,
          email: userEmail,
        },
      }
    } else {
      apiData = {
        result: {
          action: ActionTypes.REPORT,
          study_id: studyData?.study_id,
          findings: formattedFindings,
          other_findings,
          email: userEmail,
        },
      }
    }

    fetch(`${AI_SERVICE_URL}/user/action`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiData),
    })
      .then(response => response.json())
      .then(data => {
        // if programmatically window open then close the window or else navigate - browser security issues
        setIsLoading(false)
        window.close()
        navigate('/Aiflow')
      })
      .catch(error => {
        setIsLoading(false)
        navigate('/error')
        console.error('Error:', error)
      })
  }

  const handleNonReportableButtonClick = async () => {
    await dispatch(
      requestTakeAction({
        action: ActionTypes.NON_REPORTABLE,
        study_id: studyData?.study_id,
        findings: [],
        email: userEmail,
      })
    )
    window.close()
    navigate('/Aiflow')
  }

  const handleSkipButtonClick = () => {
    setIsSkipModalOpen(true)
  }

  const formatInfo = (info: any[]) => {
    return info
      .map(item =>
        Object.entries(item)
          .map(([key, value]) => key?.charAt(0)?.toUpperCase() + key?.slice(1) + ' - ' + value)
          ?.join(',')
      )
      .join(', ')
  }

  const reportChange = useCallback(
    (state: any, handle: FullScreenHandle) => {
      if (handle === screen1) {
        console.log('Screen 1 went to', state, handle)
      }
      if (handle === screen2) {
        console.log('Screen 2 went to', state, handle)
      }
    },
    [screen1, screen2]
  )

  const handleRemoveButtonClick = (index?: number) => {
    if (index !== undefined) {
      const updatedSelectedFindings = [...selectedFindings]
      updatedSelectedFindings.splice(index, 1)
      setSelectedFindings(updatedSelectedFindings)
    } else {
      setCtRatioValue('')
      setIsEditable(true)
    }
  }

  const handleRemoveView = () => {
    setView([])
  }

  const handleReportSkip = async (reason_id: number, comment: string) => {
    if (reason_id) {
      setIsSkipModalOpen(false)

      const apiData = {
        result: {
          action: ActionTypes.SKIP,
          study_id: studyData?.study_id,
          findings: [],
          email: userEmail,
          reason_id: reason_id,
          comment: comment,
        },
      }
      setIsLoading(true)
      console.log('study_id from chest skip', apiData)

      fetch(`${AI_SERVICE_URL}/user/action`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiData),
      })
        .then(response => response.json())
        .then(data => {
          console.log('API Response:', data)

          setIsLoading(false)
          setIsSkipModalOpen(false)
          window.close()
          navigate('/Aiflow')
        })
        .catch(error => {
          setIsLoading(false)
          navigate('/error')
          console.error('Error:', error)
        })
    } else {
      message.error('Please select a reason')
    }
  }

  const isBlockUserModalOpen = useMemo(() => {
    return ['NON_REPORTABLE', 'REPORTABLE', 'IN_POOL'].includes(studyData?.status)
  }, [studyData])

  const getBlockUserModalText = () => {
    if (studyData?.status === 'NON_REPORTABLE') {
      return 'This case no longer exists for review. This case is skipped'
    } else if (studyData?.status === 'IN_POOL') {
      return 'This case is not assigned to user'
    }
    return 'This case has already been reported'
  }

  const initialValues = {}

  const handleOnchangeEditorValue = (value: any) => {
    setEditorValue(value)
  }

  const renderSkipEditActionButtons = () => {
    // if (mod_study === TB_SCREENING_MOD_STUDY) {
    //   return <StyledSkipButton onClick={handleNonReportableButtonClick}>Non Reportable</StyledSkipButton>
    // }

    if (is_ai_report || mod_study === TB_SCREENING_MOD_STUDY) return <></>

    return (
      <>
        {!NON_SKIPPABLE_CLIENT_IDS.includes(studyData?.client_id) && (
          <StyledSkipButton onClick={handleSkipButtonClick}>Skip</StyledSkipButton>
        )}
        <StyledButton onClick={isEditorOpen ? () => setIsEditorOpen(false) : handleEditButtonClick}>
          {isEditorOpen ? 'Unedit' : 'Edit'}
        </StyledButton>
      </>
    )
  }

  const handleToggleRuleUpdateConfirmationModal = () => {
    setRuleUpdateConfirmationModalOpen(prev => !prev)
  }

  const renderFooterButtons = () => {
    return (
      <>
        <CloseModalButton
          onClick={handleToggleRuleUpdateConfirmationModal}
          size='large'
        >
          Cancel
        </CloseModalButton>
        <SendToPoolButton
          size='large'
          onClick={handleUpdateRule}
          loading={
            isInFlightPending(inFlights, InFlightNames.requestGetStudyInFlight) ||
            isInFlightPending(caseInflights, InFlightNames.requestUpdateModStudyInFlight)
          }
        >
          Confirm
        </SendToPoolButton>
      </>
    )
  }

  const saveRule = () => {
    if (!studySelectFunctionRef.current.RulesValidator()) {
      return
    }
    handleToggleRuleUpdateConfirmationModal()
  }

  const updateModStudyId = (id: number) => {
    setModStudyId(id)
  }

  const handleUpdateRule = async () => {
    const rules: RuleType[] = studySelectFunctionRef.current.getRules()
    await dispatch(requestUpdateModStudy({ study_id: studyData?.study_id, rules, by_user_id: id }))
    await dispatch(requestGetStudy({ id: studyData?.study_id, userId: id }))
    setSelectedFindings([])
    handleToggleRuleUpdateConfirmationModal?.()
  }

  const ruleIdsToExclude = useMemo(() => {
    const hilEligibleRuleIds: number[] = hilEligibleStudies?.map((study: any) => {
      return study?.id
    })
    const ruleIdsToExclude: number[] = []
    modStudiesList?.forEach(rule => {
      if (!hilEligibleRuleIds?.includes(rule.id)) {
        ruleIdsToExclude?.push(rule.id)
      }
    })
    if (!is_ai_report) {
      ruleIdsToExclude?.push(XRAY_BIONIC)
    }
    return ruleIdsToExclude
  }, [hilEligibleStudies, modStudiesList])

  useEffect(() => {
    setDefaultRule(studyData?.rules?.[0])
  }, [studyData])

  const isSaveButtonDisabled = () => {
    const rules = studySelectFunctionRef?.current?.getRules()
    if (!rules?.length) {
      return true
    }

    const currentSelectedRuleName = getRuleName(rules?.[0], '')
    const defaultSelectedRuleName = getRuleName(defaultRule, '')

    const isRulesMatching = doesRuleNamesMatch(defaultSelectedRuleName, currentSelectedRuleName)
    if (isRulesMatching) {
      return true
    }
    return false
  }

  useEffect(() => {
    const isButtonDisabled = isSaveButtonDisabled()
    setDisableSaveRule(isButtonDisabled)
  }, [defaultRule])

  const onRuleUpdateFailure = () => {
    if (toast.current) {
      ;(toast.current as any).show({
        severity: 'error',
        summary: 'Failed',
        detail: 'Failed to update study',
        life: 4000,
      })
    }
  }

  const onRuleUpdateSuccess = () => {
    if (toast.current) {
      ;(toast.current as any).show({
        severity: 'success',
        summary: 'Successful',
        detail: 'Study updated successfully',
        life: 4000,
      })
    }
  }
  useEffect(() => {
    const success = isInFlightSuccess(caseInflights, InFlightNames.requestUpdateModStudyInFlight)
    const failure = isInFlightFailed(caseInflights, InFlightNames.requestUpdateModStudyInFlight)
    if (success) {
      onRuleUpdateSuccess()
    }
    if (failure) {
      onRuleUpdateFailure()
    }
  }, [caseInflights])

  if (studyData.mod_study === XRAY_STUDY) {
    return (
      <XrayStudy
        studyData={studyData}
        viewer={viewer}
        updateModStudyId={updateModStudyId}
      />
    )
  }

  return (
    <div
      className='body  flex'
      style={{ background: '#F1F1F1', minHeight: '100vh' }}
    >
      <OverlayLoader
        isLoading={isLoading || isInFlightPending(inFlights, InFlightNames.requestTakeActionInFlight)}
        text={'Reporting...'}
      />
      <OverlayModal
        isModalOpen={isBlockUserModalOpen}
        content={
          <Typography.Title
            level={5}
            style={{ margin: 0, padding: '1rem', textAlign: 'center' }}
          >
            <Flex
              gap='small'
              justify='center'
            >
              <ExclamationCircleOutlined style={{ color: theme.colors.warning }} />
              {getBlockUserModalText()}
            </Flex>
          </Typography.Title>
        }
      />
      {contextHolder}
      <div className='left-wrapper'>
        <div className='left-container-chest'>
            <RuleNameContainer>
              <Text strong >{(getRuleName(defaultRule ?? {},' '))?.toUpperCase()}</Text>
            </RuleNameContainer>   
          <Formik
            initialValues={initialValues}
            onSubmit={(values, action) => {
              const data =
                FormData?.pathologies
                  ?.find((data: any) => data.pathology === selectedCategory)
                  ?.variables_fk?.map((a: number) =>
                    FormData?.variables?.find(
                      (c: any) =>
                        a === c.id &&
                        (c.selection_type === VariablesSelectionType.CHECKBOX ||
                          c.selection_type === VariablesSelectionType.TEXT_INPUT) &&
                        c.required === true
                    )
                  )
                  ?.filter(Boolean) ?? []

              let isRequiredSatisfied = true
              for (const i of data) {
                if (i?.name) {
                  if (i.selection_type === VariablesSelectionType.CHECKBOX && !(values as any)[i.name]?.length) {
                    isRequiredSatisfied = false
                    action.setFieldError(i['name'], `Please select any one of the ${i['name']}`)
                  } else if (
                    i.selection_type === VariablesSelectionType.TEXT_INPUT &&
                    !(values as any)[i.name]?.trim()
                  ) {
                    isRequiredSatisfied = false
                    action.setFieldError(i['name'], `Please fill ${i['name']}`)
                  }
                }
              }

              if (isRequiredSatisfied) {
                handleAddButtonClick(values)
                action.resetForm()
              }
            }}
          >
            {formik => (
              <Form>
                <StudySelect
                  ref={studySelectFunctionRef}
                  formName={['user', 'Studies']}
                  ruleIdsToExclude={ruleIdsToExclude ?? []}
                  defaultRule={defaultRule}
                  onChangeFunction={() => {
                    const isRuleUpdateDisabled = isSaveButtonDisabled()
                    setDisableSaveRule(isRuleUpdateDisabled)
                  }}
                />
                <FullWidthButton
                  type='default'
                  size='large'
                  onClick={saveRule}
                  disabled={disableSaveRule}
                >
                  Save
                </FullWidthButton>
                {mod_study === TB_SCREENING_MOD_STUDY && (
                  <div className='ratio-container '>
                    <div className='flex flex-column gap-2 ratio-wrapper pt-3 ml-3 mr-0 pb-3 '>
                      <label
                        htmlFor='username'
                        className=' font-bold'
                      >
                        View
                      </label>

                      <Checkbox.Group
                        options={['AP', 'PA']}
                        onChange={handleViewChange}
                      />
                    </div>
                  </div>
                )}
                <div
                  className=' findings-wrapper '
                  style={{ height: '45vh' }}
                >
                  <div
                    className='card flex '
                    style={{ height: '100%' }}
                  >
                    <div
                      className='flex flex-column gap-2 m-3'
                      style={{ width: '100%' }}
                    >
                      <label className=' findings-head'>Pathology</label>
                      <div className='card flex flex-column gap-3 '>
                        <Input
                          size='large'
                          placeholder='Search Pathology'
                          value={searchInput}
                          onChange={handleSearchInputChange}
                          style={{ borderWidth: 0 }}
                        />
                      </div>

                      {pathologies?.map((pathology: any) => (
                        <div key={pathology}>
                          <RadioButton
                            id={pathology?.pathology}
                            name='selectedPathology'
                            value={pathology?.pathology}
                            checked={selectedCategory === pathology?.pathology}
                            onChange={() => handleCategoryChange(pathology?.pathology)}
                          />
                          <label
                            htmlFor={pathology?.pathology}
                            className='p-1'
                            onClick={() => handleCategoryChange(pathology?.pathology)}
                          >
                            {pathology?.pathology}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {mod_study === 16 && (
                  <div className='ratio-container '>
                    <div className='flex flex-column gap-2 ratio-wrapper'>
                      <label
                        htmlFor='username'
                        className='mt-3 ml-3 mr-0 mb-0 font-bold'
                      >
                        Cardio-Thoracic ratio
                      </label>
                      <div className='mx-3 mt-2 mb-3'>
                        <Input
                          size='large'
                          placeholder='Ratio-value'
                          value={ctRatioValue}
                          disabled={!isEditable}
                          onChange={handleInputChange}
                          style={{ borderWidth: 0 }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className='zone1-container flex'>
                  <div className=' w-full side-container '>
                    {FormData?.pathologies
                      ?.filter((data: { pathology: string }) => data.pathology === selectedCategory)
                      ?.map((a: { varaibles_fk: any; variables_fk: string | any[] }) => {
                        const varaibles = FormData?.variables
                        return varaibles.map(
                          (c: {
                            id: any
                            mod_study_fk: number
                            options: any
                            name: string
                            required: boolean
                            selection_type: VariablesSelectionType
                          }) => {
                            const name = c.name
                            return (
                              <div className='side-wrapper '>
                                <div className=' radiobutton-container'>
                                  <label className=' findings-head'>{c.name}</label>
                                  <span
                                    className='p-1'
                                    style={{ color: 'red', fontWeight: 'bold', fontSize: '0.85em' }}
                                  >
                                    {(formik as any).errors[c.name]}
                                  </span>
                                  {a?.variables_fk?.includes(c.id) &&
                                    (c.selection_type === VariablesSelectionType.TEXT_INPUT ? (
                                      <Field
                                        name={name}
                                        required={c.required === true}
                                      />
                                    ) : (
                                      c.options?.map((item: string) => (
                                        <div key={item}>
                                          {c.selection_type === VariablesSelectionType.CHECKBOX && (
                                            <>
                                              <Field
                                                type='checkbox'
                                                id={item}
                                                name={name}
                                                value={item}
                                                onChange={formik.handleChange}
                                              />
                                              <label
                                                htmlFor={item}
                                                className='ml-2'
                                              >
                                                {item}
                                              </label>
                                            </>
                                          )}

                                          {c.selection_type === VariablesSelectionType.RADIO && (
                                            <>
                                              <Field
                                                type='radio'
                                                name={name}
                                                value={item}
                                                onClick={formik.handleChange}
                                                required={c.required === true}
                                              />
                                              <label
                                                htmlFor={item}
                                                className='p-1'
                                                onClick={() => formik.setFieldValue(name, item)}
                                              >
                                                {item}
                                              </label>
                                            </>
                                          )}
                                        </div>
                                      ))
                                    ))}
                                </div>
                              </div>
                            )
                          }
                        )
                      })}
                  </div>
                </div>
                <div className='action-container'>
                  <PathologyActionButton
                    type='default'
                    disabled={isEditorOpen || isEditorLoading}
                    size='large'
                    onClick={() => {
                      formik.resetForm()
                      handleClearButtonClick()
                    }}
                    style={{
                      borderColor: theme.colors.highlight9,
                    }}
                  >
                    Clear
                  </PathologyActionButton>
                  <PathologyAddButton
                    type='default'
                    disabled={isEditorOpen || isEditorLoading}
                    size='large'
                    onClick={() => {
                      formik.submitForm()
                    }}
                  >
                    Add
                  </PathologyAddButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className='right-container-chest'>
        <div
          className='top-section flex '
          style={{ marginBottom: '1rem' }}
        >
          <div className='selected-findings-section'>
            <div className='findings-wrapper-right'>
              <div>
                <h3>Selected Findings</h3>
                <Flex wrap='wrap'>
                  {selectedFindings.map((finding: { pathology: any; info: any[] }, index: number) => (
                    <FindingsCard
                      index={index}
                      content={`${finding.pathology} - ${formatInfo(finding.info)}`}
                      removeContent={handleRemoveButtonClick}
                      disabled={isEditorOpen || isEditorLoading}
                    />
                  ))}
                  {ctRatioValue && (
                    <FindingsCard
                      content={`Cardio-Thoracic ratio - ${ctRatioValue}`}
                      removeContent={handleRemoveButtonClick}
                      disabled={isEditorOpen || isEditorLoading}
                    />
                  )}
                  {Boolean(view?.length) && (
                    <FindingsCard
                      content={`View - ${view}`}
                      removeContent={handleRemoveView}
                    />
                  )}
                </Flex>
              </div>
            </div>
          </div>
          <div className='action-section'>
            <Tag
              color={['NON_REPORTABLE', 'REPORTABLE', 'IN_POOL'].includes(studyData?.status) ? 'red' : 'green'}
              style={{ textAlign: 'center' }}
            >
              {studyData?.status}
            </Tag>

            <StyledButton onClick={handleReportButtonClick}>{isEditorOpen ? 'Submit' : 'Report'}</StyledButton>

            {renderSkipEditActionButtons()}
          </div>
        </div>
        {isEditorLoading ? (
          <Flex justify='center'>
            <Spin />
          </Flex>
        ) : (
          isEditorOpen &&
          Boolean(editorValue?.length) && (
            <PlateEditor
              editorValue={editorValue as any}
              handleOnchangeEditorValue={handleOnchangeEditorValue}
            />
          )
        )}
        <div className='dicom-container'>
          <div className='history-section'>
            <Flex align='center'>
              <Text style={{ fontSize: 16, fontWeight: 400 }}>
                <Flex
                  align='center'
                  gap='small'
                >
                  {is_demo && (
                    <Icon
                      name='demo'
                      width={30}
                      height={30}
                    />
                  )}
                  {is_ai_report && (
                    <Icon
                      width={30}
                      name='bionic'
                    />
                  )}
                  History : &nbsp;
                </Flex>
              </Text>
              <Text>{studyData?.history}</Text>
            </Flex>
          </div>
          <div className=' dicom-viewer'>
            <div className='zoom-container'></div>
            <div className='dicom-wrapper'>
              <FullScreen
                handle={screen1}
                onChange={reportChange}
                className='fullscreen-container'
              >
                <div
                  className='full-screenable-node'
                  style={{ height: '100%' }}
                >
                  <div className='dicom-navbar'>
                    <i
                      className='pi pi-window-maximize'
                      // tooltip='Enter your username'
                      onClick={screen1.enter}
                      // width='100%'
                    ></i>
                    <i
                      onClick={screen1.exit}
                      className='pi pi-window-minimize'
                    ></i>
                  </div>
                  <iframe
                    title='Dicom-Viewer'
                    src={viewer}
                    width='100%'
                    height='100%'
                  ></iframe>
                </div>
              </FullScreen>
            </div>
          </div>
        </div>
      </div>
      <ReportSkipModal
        is_open={isSkipModalOpen}
        onSubmit={handleReportSkip}
        onCancel={() => {
          setIsSkipModalOpen(false)
        }}
      />

      <Modal
        width={500}
        centered
        closable={false}
        title={<Text>Please Confirm</Text>}
        open={ruleUpdateConfirmationModalOpen}
        onCancel={() => {}}
        footer={renderFooterButtons()}
        destroyOnClose
      >
        <ModalContent>
          <Text>{`Are sure to update the study for this case .Please confirm`}</Text>
        </ModalContent>
      </Modal>
      <Toast ref={toast} />
    </div>
  )
}

export default Reporting

const StyledButton = styled(AntButton)`
  background-color: ${theme.colors.black} !important;
  color: ${TextColors.appBackground} !important;
  padding: 1.3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  min-width: 8.5rem;
`

const StyledSkipButton = styled(StyledButton)`
  background-color: ${theme.colors.appBackground} !important;
  color: ${TextColors.black} !important;
`

const PathologyActionButton = styled(AntButton)`
  background-color: ${theme.colors.dull} !important;
  color: ${TextColors.black} !important;
  font-weight: bold;
  font-size: 1rem;
  min-width: 5.3rem;
`

const PathologyAddButton = styled(PathologyActionButton)`
  background-color: ${theme.colors.black} !important;
  color: ${TextColors.appBackground} !important;
`

const FullWidthButton = styled(AntButton)`
  color: ${TextColors.appBackground};
  font-weight: bold;
  font-size: 1rem;
  width: 96%;
  background-color: ${theme.colors.black};
  margin: 0.5rem 2%;
  margin-bottom: 2rem;
`

const SendToPoolButton = styled(AntButton)`
  color: ${TextColors.appBackground};
  font-weight: bold;
  font-size: 1rem;
  background-color: ${theme.colors.black};
  margin: 0.5rem 2%;
  margin-bottom: 2rem;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem 3rem;
`

const CloseModalButton = styled(AntButton)`
  color: ${theme.colors.black};
  font-weight: bold;
  font-size: 1rem;
  background-color: ${TextColors.appBackground};
  margin: 0.5rem 2%;
  margin-bottom: 2rem;
`
const RuleNameContainer = styled.div`
padding: 1rem;
border-radius: 0.5rem;
width: 96%;
margin: auto;
margin-top: 0.5rem;
background-color: #d9d9d9;
`