import React, { useState, useEffect, useRef, useMemo } from 'react'
import '../App.css'
import '/node_modules/primeflex/primeflex.css'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Dropdown as AntDropdown, Space, Button as AntButton, Typography, Spin, Input } from 'antd'
import { ClearOutlined, DownOutlined, LoadingOutlined, SearchOutlined, ThunderboltOutlined } from '@ant-design/icons'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import { Tag } from 'primereact/tag'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import { Badge } from 'primereact/badge'
import forwards from '../Assets/forward.png'
import axios from 'axios'
import { auth } from '../Auth/firebaseAuth'
import { AI_SERVICE_URL, ModStudy, ModStudyMap, StudyCountStatuses, channel } from '../utils/constants'
import { Toast } from 'primereact/toast'
import { MenuProps } from 'antd'
import { theme } from '../components/shared/theme'
import { Flex } from 'antd'
import CountCard from '../components/CountCard'
import Icon from '../components/shared/Icon'
import OverlayLoader from '../components/shared/OverlayLoader'
import styled from 'styled-components'
import { StudyStatuses } from '../redux/cases/caseSlice'

type CaseCount = {
  status: StudyStatuses
  count: number
}

type ModStudyCount = {
  [mod_study: string | number]: CaseCount[]
}

export default function Homepage() {
  const [filters, setFilters] = useState<any>({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
    patient_name: {
      operator: FilterOperator.AND,
      constraints: [{ value: '', matchMode: FilterMatchMode.CONTAINS }],
    },
    'country.name': {
      operator: FilterOperator.AND,
      constraints: [{ value: '', matchMode: FilterMatchMode.STARTS_WITH }],
    },
    date: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    status: {
      operator: FilterOperator.OR,
      constraints: [{ value: '', matchMode: FilterMatchMode.EQUALS }],
    },
  })
  const [loading, setLoading] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [statuses] = useState<string[]>(['NON_REPORTABLE', 'REPORTABLE', 'ASSIGNED', 'IN_POOL', 'MODEL_WAIT', 'OPENED'])
  const [data, setdata] = useState<any>([])
  const [caseCountData, setCaseCountData] = useState<ModStudyCount | undefined>()
  const [selectedDate, setSelectedDate] = useState<any>(new Date())

  const [userEmail, setUserEmail] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)

  const [count, setCount] = useState({ pool_count: 0, special_case_count: 0 })
  const [isDemo, setIsDemo] = useState<boolean>(false)
  const [users, setUsers] = useState([])

  const toast = useRef(null)

  const { Text } = Typography

  const getSeverity = (status: string) => {
    let mappedStatus = ''
    let color = ''

    switch (status) {
      case 'REPORTABLE':
        mappedStatus = 'Reported'
        color = 'success'
        break
      case 'NON_REPORTABLE':
        mappedStatus = 'Skipped'
        color = 'danger'
        break
      case 'WAIT':
        mappedStatus = 'Waiting'
        color = 'info'
        break
      case 'ASSIGNED':
        mappedStatus = 'Assigned'
        color = 'info'
        break
      case 'IN_POOL':
        mappedStatus = 'In Pool'
        color = 'success'
        break
      case 'MODEL_WAIT':
        mappedStatus = 'Waiting - Model'
        color = 'info'
        break
      default:
        mappedStatus = status
        color = 'default'
        break
    }

    return { mappedStatus, color }
  }

  useEffect(() => {
    setIsPageLoading(true)

    if (userEmail) {
      apiCall(selectedDate).then(data => setIsPageLoading(false))

      const interval = setInterval(() => {
        apiCall(selectedDate)
      }, 5000)

      return () => clearInterval(interval)
    }
  }, [selectedDate, userEmail])

  useEffect(()=>{
    const handleBroadCastEvent = (event:any) => {
      if(event?.data === 'refetch-pool-data'){
        apiCall(selectedDate)
      }
   }
   channel.addEventListener('message',handleBroadCastEvent );

   return(()=> {channel.removeEventListener('message',handleBroadCastEvent)})
  },[selectedDate])



  const reassignUser = (study_id: number, user_id: number, setIsReassignLoading: any) => {
    fetch(`${AI_SERVICE_URL}/user/reassign/${study_id}/${user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Something went wrong!')
        }
        return response.json()
      })
      .then(data => {
        ;(toast.current as any)?.show({
          severity: 'success',
          summary: 'Success',
          detail: data?.message,
          life: 4000,
        })
        setIsReassignLoading(false)
        setdata((prevState: any) =>
          prevState?.map((item: any) =>
            item.study_id === study_id
              ? {
                  ...item,
                  status: 'ASSIGNED',
                  user_id: user_id,
                  user_name: (users.find((u: { id: number }) => u.id === user_id) as any)?.name,
                }
              : item
          )
        )
      })
      .catch(error => {
        ;(toast.current as any).show({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to reassign case: ${error.message}`,
          life: 4000,
        })
        setIsReassignLoading(false)
      })
  }

  const getUsers = async () => {
    try {
      const users = await axios.get(`${AI_SERVICE_URL}/user/users`)
      setUsers(users?.data?.users)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const apiCall = async (selectedDate: any) => {
    const formattedDate = `${selectedDate.getFullYear()}-${String(selectedDate.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(selectedDate.getDate()).padStart(2, '0')}`
    const apiURL = `${AI_SERVICE_URL}/user/cases?date=${formattedDate}&user=${userEmail}`

    try {
      const response = await axios.get(apiURL)
      const sortedData = response.data.data.cases.sort(
        (a: { status: string; created_at: any }, b: { status: string; created_at: any }) => {
          if (a.status === 'OPENED' && b.status === 'OPENED') {
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          } else if (a.status === 'OPENED') {
            return -1
          } else if (b.status === 'OPENED') {
            return 1
          } else if (a.status === 'ASSIGNED' && b.status === 'ASSIGNED') {
            return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          } else if (a.status === 'ASSIGNED') {
            return -1
          } else if (b.status === 'ASSIGNED') {
            return 1
          } else if (
            (a.status === 'REPORTABLE' || a.status === 'NON_REPORTABLE') &&
            (b.status === 'REPORTABLE' || b.status === 'NON_REPORTABLE')
          ) {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          } else {
            return 0
          }
        }
      )
      setdata(sortedData)
      setCaseCountData(response.data.data.count)
      setIsDemo(response.data.data.is_demo)
    } catch (error) {
      setIsPageLoading(false)
      console.error('Error fetching data:', error)
    }
  }
  useEffect(() => {
    const user = auth.currentUser
    if (user) {
      setUserEmail(user.email!)
    } else {
      setUserEmail('')
    }

    if (!users?.length) {
      getUsers()
    }
  }, [])

  const clearFilter = () => {
    initFilters()
  }

  const onGlobalFilterChange = (e: { target: { value: string } }) => {
    const value = e.target.value.toLowerCase()
    // eslint-disable-next-line
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      patient_name: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      'country.name': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    })
    setGlobalFilterValue('')
  }

  const getNewCase = () => {
    setIsLoading(true)
    fetch(`${AI_SERVICE_URL}/user/get-next-case?email=${userEmail}`, {
      method: 'GET',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        }
        return response.json()
      })
      .then(data => {
        if (!data.id) {
          // Show toast for missing id
          ;(toast.current as any).show({
            severity: 'warn',
            summary: 'Warning',
            detail: 'No case found!',
            life: 4000,
          })
        } else {
          console.log(data)
          window.open(`/Cases/${data.id}`, '_blank')
        }
        setIsLoading(false)
      })
      .catch(error => {
        // Show toast for general error
        ;(toast.current as any).show({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to Get new case: ${error.message}`,
          life: 4000,
        })
        setIsLoading(false)
      })
  }

  const getPoolCount = () => {
    setIsLoading(true)
    fetch(`${AI_SERVICE_URL}/user/get-pool-count?user_email=${userEmail}`, {
      method: 'GET',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch data')
        } else {
          // Parse response body as JSON
          return response.json()
        }
      })
      .then(data => {
        if (data) {
          setCount(data)
        } else {
          console.log('failed')
        }
        setIsLoading(false)
      })
      .catch(error => {
        // Show toast for general error
        ;(toast.current as any).show({
          severity: 'error',
          summary: 'Error',
          detail: `Failed to Get count: ${error.message}`,
          life: 4000,
        })
        setIsLoading(false)
      })
  }

  const getCountData = useMemo(() => {
    const modStudyList = [
      ModStudy.SPINE,
      ModStudy.CHEST,
      ModStudy.KNEE,
      ModStudy.SHOULDER,
      ModStudy.ELBOW,
      ModStudy.KUB,
      ModStudy.LEG,
      ModStudy.THIGH,
      ModStudy.ANKLE,
      ModStudy.FOOT,
      ModStudy.HEEL,
      ModStudy.FOREAREM,
      ModStudy.HAND,
      ModStudy.WRIST,
      ModStudy.ARM,
      ModStudy.PELVIS,
      ModStudy.ADBOMEN,
      ModStudy.HIP,
    ]
    const result: { [key: string]: { [key: string]: number } } = {
      TOTAL: { [StudyCountStatuses.REPORTED]: 0, [StudyCountStatuses.SKIPPED]: 0, [StudyCountStatuses.ACTIVE]: 0 },
      [ModStudy.SPINE]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.CHEST]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.KNEE]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.SHOULDER]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.ELBOW]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.KUB]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.LEG]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.THIGH]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.HEEL]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.FOOT]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.ANKLE]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.FOREAREM]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.ARM]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.HAND]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.WRIST]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.HIP]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.PELVIS]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
      [ModStudy.ADBOMEN]: {
        [StudyCountStatuses.REPORTED]: 0,
        [StudyCountStatuses.SKIPPED]: 0,
        [StudyCountStatuses.ACTIVE]: 0,
      },
    }
    for (const study of modStudyList) {
      for (const modStudyId of ModStudyMap[study]) {
        const data = caseCountData?.[modStudyId]
        if (data) {
          for (const val of data) {
            switch (val.status) {
              case 'REPORTABLE':
                result[study][StudyCountStatuses.REPORTED] += val.count
                result.TOTAL[StudyCountStatuses.REPORTED] += val.count
                break
              case 'NON_REPORTABLE':
                result[study][StudyCountStatuses.SKIPPED] += val.count
                result.TOTAL[StudyCountStatuses.SKIPPED] += val.count
                break
              case 'ASSIGNED':
              case 'OPENED':
                result[study][StudyCountStatuses.ACTIVE] += val.count
                result.TOTAL[StudyCountStatuses.ACTIVE] += val.count
                break
            }
          }
        }
      }
    }

    const data = [
      {
        type: 'Overall',
        study: 'TOTAL',
        icon: 'overall-case',
        bgColor: theme.colors.primaryLight,
        color: theme.colors.primary,
      },
      {
        type: 'Chest',
        study: ModStudy.CHEST,
        icon: 'chest',
        bgColor: theme.colors.lightWarning,
        color: theme.colors.warning,
      },
      {
        type: 'Knee',
        study: ModStudy.KNEE,
        icon: 'knee',
        bgColor: theme.colors.lightPurple,
        color: theme.colors.purple,
      },
      {
        type: 'Spine',
        study: ModStudy.SPINE,
        icon: 'spine',
        bgColor: theme.colors.lightSuccess,
        color: theme.colors.success,
      },
      {
        type: 'Shoulder',
        study: ModStudy.SHOULDER,
        icon: 'shoulder',
        bgColor: theme.colors.ternaryLighter,
        color: theme.colors.ternary,
      },
      {
        type: 'Elbow',
        study: ModStudy.ELBOW,
        icon: 'elbow',
        bgColor: theme.colors.primaryLight,
        color: theme.colors.primary,
      },
      {
        type: 'KUB',
        study: ModStudy.KUB,
        icon: 'kidney',
        bgColor: theme.colors.tagbackground4,
        color: theme.colors.highlight1,
      },
      {
        type: 'Leg',
        study: ModStudy.LEG,
        icon: 'leg',
        bgColor: theme.colors.tagbackground3,
        color: theme.colors.highlight8,
      },
      {
        type: 'Ankle',
        study: ModStudy.ANKLE,
        icon: 'ankle',
        bgColor: theme.colors.tagbackground4,
        color: theme.colors.warningGradient.stop2,
      },
      {
        type: 'Thigh',
        study: ModStudy.THIGH,
        icon: 'thigh',
        bgColor: theme.colors.tagbackground5,
        color: theme.colors.highlight6,
      },
      {
        type: 'Foot',
        study: ModStudy.FOOT,
        icon: 'foot',
        bgColor: theme.colors.caseCritical,
        color: theme.colors.infoGradient.stop2,
      },
      {
        type: 'Heel',
        study: ModStudy.HEEL,
        icon: 'heel',
        bgColor: theme.colors.lightSuccess,
        color: theme.colors.success,
      },
      {
        type: 'Arm',
        study: ModStudy.ARM,
        icon: 'arm',
        bgColor: theme.colors.lightWarning,
        color: theme.colors.warning,
      },
      {
        type: 'Forearm',
        study: ModStudy.FOREAREM,
        icon: 'forearm',
        bgColor: theme.colors.lightPurple,
        color: theme.colors.purple,
      },
      {
        type: 'Hand',
        study: ModStudy.HAND,
        icon: 'hand',
        bgColor: theme.colors.primaryLight,
        color: theme.colors.primary,
      },
      {
        type: 'Wrist',
        study: ModStudy.WRIST,
        icon: 'wrist',
        bgColor: theme.colors.ternaryLighter,
        color: theme.colors.ternary,
      },
      {
        type: 'Hip',
        study: ModStudy.HIP,
        icon: 'hip',
        bgColor: theme.colors.tagbackground4,
        color: theme.colors.warningGradient.stop2,
      },
      {
        type: 'Abdomen',
        study: ModStudy.ADBOMEN,
        icon: 'abdomen',
        bgColor: theme.colors.tagbackground5,
        color: theme.colors.highlight6,
      },
      {
        type: 'Pelvis',
        study: ModStudy.PELVIS,
        icon: 'pelvis',
        bgColor: theme.colors.caseCritical,
        color: theme.colors.infoGradient.stop2,
      },
    ]

    return data.map(({ type, study, icon, bgColor, color }) => ({
      type,
      data: result[study],
      total: Object.values(result[study]).reduce((acc: number, curr: any) => acc + curr, 0),
      icon,
      bgColor,
      color,
    }))
  }, [caseCountData])

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null
    const fetchPoolCount = () => {
      if (userEmail) {
        getPoolCount()
      }
    }

    if (userEmail) {
      fetchPoolCount()
      intervalId = setInterval(fetchPoolCount, 10000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [userEmail])

  const renderHeader = () => {
    return (
      <div className='flex justify-content-between'>
        <StyledButton
          size={'large'}
          type='default'
          icon={
            <ClearOutlined
              style={{
                color: theme.colors.purple,
                fontWeight: 'bold',
                fontSize: '1.5rem',
              }}
            />
          }
          onClick={clearFilter}
          style={{
            borderColor: theme.colors.purple,
            fontWeight: 'bold',
            color: theme.colors.purple,
          }}
        >
          Clear
        </StyledButton>
        <div className='flex-horizontal'>
          <StyledButton
            size={'large'}
            type='text'
            loading={isLoading}
            icon={
              <ThunderboltOutlined
                style={{
                  color: theme.colors.warning,
                  fontWeight: 'bold',
                  fontSize: '1.5rem',
                }}
              />
            }
            onClick={getNewCase}
            style={{
              color: theme.colors.warning,
              borderColor: theme.colors.warning,
              fontWeight: 'bold',
            }}
          >
            {`Get New Case (${count.special_case_count ? `${count.special_case_count} / ` : ''}${count.pool_count})  `}
          </StyledButton>
          <StyledInput
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            prefix={<SearchOutlined style={{ fontSize: '1.5rem' }} />}
            placeholder='Name Search'
          />
        </div>
        <Toast ref={toast} />
      </div>
    )
  }

  // const filterApplyTemplate = (options) => {
  //     return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
  // };

  const dateFilterTemplate = () => {
    return (
      <Calendar
        value={selectedDate}
        onChange={e => setSelectedDate(e.value)}
        dateFormat='mm/dd/yy'
        placeholder='mm/dd/yyyy'
        mask='99/99/9999'
        showIcon
      />
    )
  }

  const balanceBodyTemplate = (rowData: { order_id: string; study_id: number }) => {
    return (
      <Flex vertical={true}>
        {rowData.order_id}

        <a
          href={`https://admin.5cnetwork.com/cases/${rowData.study_id}?tab=Images`}
          target='_blank'
          className='case-hyper-link'
        >
          {rowData.study_id}
        </a>
      </Flex>
    )
  }

  const renderCaseTypeIndicator = (rowData: { is_critical: boolean; is_demo: boolean; is_ai_report: boolean }) => {
    return (
      <Flex gap='small'>
        {rowData.is_critical && (
          <Icon
            width={30}
            height={30}
            name='critical'
          />
        )}
        {rowData.is_demo && (
          <Icon
            width={30}
            height={30}
            name='demo'
          />
        )}
        {rowData.is_ai_report && (
          <Icon
            width={30}
            name='bionic'
          />
        )}
      </Flex>
    )
  }

  const patient_name = (rowData: { patient_name: string }) => {
    return rowData.patient_name
  }

  const Study_Name = (rowData: { report_header: string }) => {
    return rowData.report_header
  }
  const mod_study = (rowData: { mod_study: any }) => {
    return rowData.mod_study
  }

  const dateBodyTemplate = (rowData: { created_at: any }) => {
    const formattedDate = new Date(rowData.created_at).toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })

    const datePart = formattedDate.slice(0, -8)
    const timePart = formattedDate.slice(-8)

    return (
      <div>
        <div className='date-part'>{datePart} </div>
        <div className='time-part'>{timePart}</div>
      </div>
    )
  }

  const statusBodyTemplate = (rowData: { status: string }) => {
    const { mappedStatus, color } = getSeverity(rowData.status)
    return (
      <Badge
        value={mappedStatus.toUpperCase()}
        severity={color as any}
      />
    )
  }

  const renderAssignUser = (rowData: { status: string; user_name: string; study_id: number; user_id: number }) => {
    const [isReassignLoading, setIsReassignLoading] = useState<boolean>(false)

    const items: MenuProps['items'] = users?.map((usr: { name: string; id: number }) => ({
      key: usr.id,
      label: (
        <a
          onClick={() => {
            if (rowData?.user_id != usr.id) {
              setIsReassignLoading(true)
              reassignUser(rowData?.study_id, usr.id, setIsReassignLoading)
            }
          }}
        >
          {usr.name}
        </a>
      ),
    }))

    if (isReassignLoading) {
      return (
        <AntButton
          type='text'
          style={{ textAlign: 'center' }}
        >
          <Spin
            indicator={<LoadingOutlined spin />}
            size='default'
          />
        </AntButton>
      )
    }

    if (['ASSIGNED', 'OPENED', 'IN_POOL'].includes(rowData?.status)) {
      return (
        <AntDropdown
          menu={{ items }}
          placement='bottom'
        >
          <AntButton type='text'>
            <Space>
              {rowData.user_name ? rowData.user_name.charAt(0).toUpperCase() + rowData.user_name.slice(1) : '-'}
              <DownOutlined />
            </Space>
          </AntButton>
        </AntDropdown>
      )
    }

    return (
      <AntButton type='text'>
        <Text>{rowData.user_name ? rowData.user_name.charAt(0).toUpperCase() + rowData.user_name.slice(1) : '-'}</Text>
      </AntButton>
    )
  }

  const statusFilterTemplate = () => {
    const statusLabels: { [key: string]: string } = {
      REPORTABLE: 'Reported',
      NON_REPORTABLE: 'Skipped',
      WAIT: 'Waiting',
      ASSIGNED: 'Assigned',
      IN_POOL: 'In Pool',
      MODEL_WAIT: 'Waiting - Model',
      OPENED: 'Opened',
    }
    const onStatusFilterChange = (selectedStatus: string) => {
      // eslint-disable-next-line
      let _filters = { ...filters }
      _filters['status'].constraints[0].value = selectedStatus
      setFilters(_filters)
    }

    return (
      <Dropdown
        value={filters.status.constraints[0].value}
        options={statuses.map(status => ({
          label: (
            <Tag
              value={statusLabels[status].toUpperCase()}
              severity={getSeverity(status).color as any}
            />
          ),
          value: status,
        }))}
        onChange={e => onStatusFilterChange(e.value)}
        placeholder='Select One'
        className='p-column-filter'
        showClear
      />
    )
  }

  const actionBodyTemplate = (rowData: { study_id: number }) => {
    const { study_id } = rowData

    const handleRedirect = () => {
      window.open(`/Cases/${study_id}`, '_blank')
    }

    return (
      <div
        onClick={handleRedirect}
        className='cursor-pointer'
      >
        <img
          width='38'
          height='38'
          src={forwards}
          alt='report'
          className='forward'
        />
      </div>
    )
  }

  const header = renderHeader()

  return (
    <div style={{ margin: '0rem 1rem' }}>
      <OverlayLoader isLoading={isPageLoading} />
      <div>
        <div style={{ overflowX: 'auto', scrollbarWidth: 'thin' }}>
          <Flex
            gap={'middle'}
            className=' mb-2 p-0'
            style={{ minWidth: 'max-content' }}
          >
            {getCountData.map((items, index) => (
              <CountCard
                count={items}
                key={index}
              />
            ))}
          </Flex>
        </div>
      </div>
      <div style={{ marginTop: '1rem' }}>
        <DataTable
          value={data}
          paginator
          showGridlines
          rows={10}
          loading={loading}
          dataKey='id'
          filters={filters}
          globalFilterFields={['patient_name', 'status']}
          emptyMessage='No data found.'
          header={header}
        >
          <Column
            header=''
            style={{ maxWidth: '5rem' }}
            body={renderCaseTypeIndicator}
          />
          <Column
            header='Study ID'
            alignHeader={'center'}
            align={'center'}
            style={{ minWidth: '10rem' }}
            body={balanceBodyTemplate}
          />
          <Column
            field='patient_name'
            header='Patient Name'
            filterField='patient_name'
            style={{ minWidth: '12rem' }}
            body={patient_name}
          />
          <Column
            header='Study Name'
            style={{ minWidth: '12rem' }}
            body={Study_Name}
          />
          <Column
            header='Mod_study'
            style={{ minWidth: '12rem', display: 'none' }}
            body={mod_study}
            className='modstudy'
          />
          <Column
            header='Created at'
            filterField='date'
            dataType='date'
            style={{ minWidth: '10rem' }}
            body={dateBodyTemplate}
            filter
            filterElement={dateFilterTemplate}
          />
          {isDemo && (
            <Column
              style={{ minWidth: '12rem' }}
              header='Assigned To'
              body={renderAssignUser}
            />
          )}
          <Column
            field='status'
            header='Status'
            filterMenuStyle={{ width: '14rem' }}
            style={{ minWidth: '10rem' }}
            body={statusBodyTemplate}
            filter
            filterElement={statusFilterTemplate}
          />
          <Column
            header='View'
            style={{ maxWidth: '5rem' }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    </div>
  )
}

const StyledButton = styled(AntButton)`
  display: flex;
  align-items: center;
  padding: 1.4rem 1rem;
`

const StyledInput = styled(Input)`
  font-size: 1.1rem;
  font-weight: normal;
  border-color: ${theme.colors.disabledMonth};
  margin-left: 1rem;
`
